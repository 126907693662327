<template>
  <li class="list-main__item" :class="['text-' + alignment, { 'p-half': !width }]">
    <a
      v-if="isReport(data.Value01)"
      :href="getReportHandlerUrl(data.Sessionnumber, data.Value01)"
      :title="Lanqkey01"
      :class="['w-' + width, { 'is-disabled': buttonState.disabled }]"
      class="el-button el-button--primary"
      target="_blank"
      >{{ Lanqkey01 }}</a
    >

    <el-button
      v-else-if="isDownloadable(data.Value01)"
      :class="['w-' + width, { 'is-disabled': buttonState.disabled || isLoading }]"
      type="primary"
      @click.prevent="onClickButton"
      :loading="isLoading"
      >{{ Lanqkey01 }}</el-button
    >

    <el-button v-else :class="['w-' + width, { 'is-disabled': buttonState.disabled }]" type="primary">{{ Lanqkey01 }}</el-button>

    <!-- Debug -->
    <DebugItem v-if="debug.visible" :data="data"></DebugItem>
  </li>
</template>

<script>
/* eslint-disable arrow-body-style */
import { get } from 'lodash';
import DebugItem from '@/components/DebugItem';
import mixins from '@/shared/mixins/index';

export default {
  name: 'BUTTON',

  mixins: [mixins],

  components: {
    DebugItem
  },

  props: {
    alignment: {
      type: String,
      default: 'left'
    },

    buttonState: {
      type: Object,
      required: false,
      default: () => {
        return {
          disabled: false
        };
      }
    },

    data: {
      type: Object,
      required: true
    },

    width: {
      type: Number,
      required: false,
      default: null
    }
  },

  computed: {
    Lanqkey01() {
      return get(this.data, 'Lanqkey01');
    },

    Xnodekey() {
      return get(this.data, 'Xnodekey');
    }
  },

  data() {
    return {
      downloadButtons: ['MASSBLATTDOWNLOAD', 'PLANUNGDOWNLOAD', 'MASSBLATTMAIL', 'MASSBLATTDOWNLOADRVT'],
      reportButton: ['TDPDF', 'PDPDF', 'LSTPDF'],
      isLoading: false
    };
  },

  methods: {
    isDownloadable(string) {
      return this.downloadButtons.includes(string);
    },

    isReport(string) {
      return this.reportButton.includes(string);
    },

    onClickButton() {
      this.$store.commit('pages/SET_PAGES_LOADING', { loading: true, nodeKey: this.Xnodekey });
      this.isLoading = true;
      const TIMEOUT = 10000;

      this.requestNode(this.Xnodekey)
        .then(result => {
          const RESPONSE = get(result, 'data.RESPONSE');
          if (RESPONSE) {
            this.handleNode(RESPONSE);
          }
          this.$store.commit('pages/HIDE_LOADING');
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false;
          }, TIMEOUT);
        });
    }
  }
};
</script>
